import type { ReactNode } from 'react'
import NextLink from 'next/link'

interface Link {
  children: ReactNode,
  href: string,
  target?: '_self' | '_blank',
  rel?: 'nofollow noopener',
}

function Link( {
  children, 
  href, 
  target = null, 
  rel = null, 
  ...props
}: Link ) {
  const isExternal = isExternalUrl( href )
  const linkTarget = target || isExternal ? '_blank' : '_self'
  const linkRel = rel || linkTarget === '_blank' ? 'nofollow noopener' : null

  if ( target === '_blank' || isExternal ) {
    return (
      <a 
        href={ href }
        target={ linkTarget }
        rel={ linkRel }
      >
        { children }
      </a>
    )
  }

  return (
    <NextLink
    // eslint-disable-next-line react/jsx-props-no-spreading
      { ...props }
      href={ href }
      // scroll={ false }
    >
      <a>
        { children }
      </a>
    </NextLink>
  )
}

export function ConditionalLink( {
  children, 
  Component,
  href = null, 
  target = null, 
  rel = null, 
  ...props
} ) {
  if ( href ) {
    return (
      <Link
        href={ href }
        target={ target }
        rel={ rel } 
        // eslint-disable-next-line react/jsx-props-no-spreading
        { ...props }
      >
        { children }
      </Link>
    )
  }

  return (
    <Component>
      { children }
    </Component>
  )
}

// process.env.NEXT_PUBLIC_WEBCLIENT_HOSTNAME is an env var, string in the format: `example.com`
export const isExternalUrl = url => new URL( url, `https://${ process.env.NEXT_PUBLIC_APP_HOSTNAME }` ).hostname !== process.env.NEXT_PUBLIC_APP_HOSTNAME

export default Link
