import { useState } from 'react'
import styled from 'styled-components'
import useWindowScroll from '../hooks/useWindowScroll'
import Header from './Header'
import Footer from './Footer'
import { CookieConsentBanner } from './CookieConsent'
import MetaTags from './MetaTags'
import LetsDiscussButton from './LetsDiscussButton'

function AppChrome( { 
  metaTags = null, 
  pageHasSplash = false, 
  footer = true,
  letsDiscussButton = true,
  darkPage = false,
  logoColor = null,
  noLogo = false,
  children, 
} ) {
  const [isSplashVisible, setIsSplashVisible] = useState( pageHasSplash )

  useWindowScroll( () => {
    if ( pageHasSplash ) {
      if ( isSplashVisible && window.scrollY >= window.innerHeight ) {
        setIsSplashVisible( false )
      }
      
      if ( !isSplashVisible && window.scrollY < window.innerHeight ) {
        setIsSplashVisible( true )
      }
    }
  }, [pageHasSplash, isSplashVisible] )

  return (
    <StyledAppChrome>
      { metaTags && (
        <MetaTags 
        // eslint-disable-next-line react/jsx-props-no-spreading
          { ...metaTags } 
        />
      ) }
      <LetsDiscussButton visible={ letsDiscussButton && !isSplashVisible } />
      <Header 
        isSplashVisible={ isSplashVisible } 
        darkPage={ darkPage }
        logoColor={ logoColor }
        noLogo={ noLogo }
      />
      <StyledAppMain>
        { children }
      </StyledAppMain>
      { footer && <Footer /> }
      <CookieConsentBanner />
    </StyledAppChrome>
  )
}

const StyledAppChrome = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${ p => p.theme.colors.white };
  cursor: ${ p => p.theme.isLoading ? 'wait' : 'auto' };
`

const StyledAppMain = styled.div`
  flex: 1;
  min-height: 75vh;
`

export default AppChrome
