export type TypeActions =
  'UPLOAD_FILES'

const intersects = ( arr1, arr2 ) => arr1.filter( i1 => arr2.find( i2 => i2 === i1 ) ).length > 0

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const can = ( allowedRoles, _userProps ) => ( action: TypeActions, _props = {} as any ) => {
  if ( !allowedRoles ) {
    return false
  }

  if ( action === 'UPLOAD_FILES' ) {
    return intersects( allowedRoles, ['HOD_ADMIN', 'USER'] )
  }

  return false
}

export default can
