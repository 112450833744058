import IntlProvider from './IntlProvider'
import { AuthProvider } from '../lib/auth'
import ApolloProvider from '../graphql/ApolloProvider'
import ThemeProvider from '../styles/ThemeProvider'
import { CookieConsentProvider } from './CookieConsent'
import { AppProvider } from './AppContext'
import { ChatProvider } from './chat/ChatContext'
import { ModalProvider } from '../lib/modal'
import { BodyScrollProvider } from '../hooks/usePreventBodyScroll'
import Modal from './Modal'

function ContextProviders( { globals, children } ) {
  return (
    <AuthProvider>
      <ApolloProvider>
        <ThemeProvider>
          <BodyScrollProvider>
            <IntlProvider>
              <CookieConsentProvider>
                <AppProvider globals={ globals }>
                  <ChatProvider>
                    <ModalProvider Modal={ Modal }>
                      { children }
                    </ModalProvider>
                  </ChatProvider>
                </AppProvider>
              </CookieConsentProvider>
            </IntlProvider>
          </BodyScrollProvider>
        </ThemeProvider>
      </ApolloProvider>
    </AuthProvider>
  )
}

export default ContextProviders
