import type { MutableRefObject } from 'react'
import { useRouter } from 'next/router'
import {
  useState, createContext, useContext, useMemo, useCallback, useEffect, useRef,
} from 'react'
import { useChatbotContentQuery } from '../../graphql/chat'

type ChatItem = {
  id: number,
  [key: string]: any,
}

type Activity = {
  id: number,
  title: string,
  slug: string,
}

type ChatLogItem = {
  action: 'START' | 'HOD' | 'RESPONSE',
  id: number,
  time: number,
  localString: string,
  botContent?: Array<string>,
  defaultUrl?: string,
  defaultNext?: Array<{
    id: string,
  }>,
}

interface ChatContext {
  chatItems: Array<ChatItem>,
  activities: Array<Activity>,
  pendingChatItems: Array<ChatItem>
  chatLog: Array<ChatLogItem>,
  chatVariables: {
    [key: string]: object | string | number,
  },
  chatCanvasRef: MutableRefObject<HTMLDivElement>,
  getChatItem: ( id?: string ) => ChatItem,
  appendPendingChatItem: ( chatItem ) => void,
  applyPendingChatItems: () => void,
  appendLog: ( chatItem: ChatItem | Array<ChatItem> ) => void,
  setUserResponse: ( logLogIndex, response ) => void,
  setChatVariable: ( key, value ) => void,
  scrollToLatest: () => void,
  resetChat: () => void,
}

const ChatContext = createContext( {} as ChatContext )

ChatContext.displayName = 'ChatContext'

export const useChatContext = () => useContext( ChatContext )

const useUnload = fn => {
  const callback = useCallback( fn, [fn] )

  useEffect( () => {
    window.addEventListener( 'beforeunload', callback )
    
    return () => {
      window.removeEventListener( 'beforeunload', callback )
    }
  }, [callback] )
}

export function ChatProvider( { children } ) {
  const router = useRouter()
  const [chatItems, setChatItems] = useState( [] )
  const [pendingChatItems, setPendingChatItems] = useState( [] )
  const [chatLog, setChatLog] = useState( [] )
  const [chatVariables, setChatVariables] = useState( {} )
  const chatCanvasRef = useRef<HTMLDivElement>( null )
  const pendingItemsTimerRef = useRef( null )
  const { data } = useChatbotContentQuery( router.locale || router.defaultLocale || 'en' )
  const serializedChat = JSON.stringify( data?.chat )
  const serializedActivities = JSON.stringify( data?.activities )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadedChatItems = useMemo( () => data?.chat || [], [serializedChat] )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activities = useMemo( () => data?.activities || [], [serializedActivities] )

  useUnload( e => {
    if ( chatLog.length > 2 ) {
      e.preventDefault()
      const msg = 'Would you like to save your chat with Lucy before you leave?'
      e.returnValue = msg
      return msg
    }
  } )

  const getChatItem = useCallback( ( id = null ) => {
    if ( id ) {
      return chatItems.find( item => item.id === id )
    }

    const currentItemId = chatLog[ chatLog.length - 1 ]?.id

    if ( !currentItemId ) {
      return chatItems[ 0 ]
    }

    const currentItemIndex = chatItems.findIndex( item => item.id === currentItemId )

    if ( currentItemIndex < chatItems.length - 1 ) {
      return chatItems[ currentItemIndex + 1 ]
    }

    return null
  }, [chatLog, chatItems] )

  const appendLog = chatItem => {
    const items = Array.isArray( chatItem ) ? chatItem : [chatItem]
    const date = new Date()

    setChatLog( s => [
      ...s, 
      ...items.map( item => ( { 
        action: 'HOD',
        ...item, 
        time: date.getTime(),
        localeString: date.toLocaleString(), 
      } ) ),
    ] )
  }

  const appendPendingChatItem = chatItem => {
    setPendingChatItems( s => [
      ...s,
      chatItem,
    ] )
  }

  const applyPendingChatItems = useCallback( () => {
    if ( pendingChatItems.length ) {
      const pending = [...pendingChatItems]
      setPendingChatItems( [] )
      appendLog( pending )
    }
  }, [pendingChatItems] )

  const setUserResponse = ( logLogIndex, userResponse ) => {
    setChatLog( 
      s => s.map( ( logItem, i ) => i === logLogIndex ? { ...logItem, userResponse } : logItem ), 
    )
  }

  const setChatVariable = ( key, value ) => {
    setChatVariables( s => ( { ...s, [ key ]: value } ) )
  }

  const scrollToLatest = useCallback( () => {
    if ( chatCanvasRef.current ) {
      chatCanvasRef.current.scrollIntoView( { behavior: 'smooth', block: 'end' } )

      if ( pendingItemsTimerRef.current ) {
        clearTimeout( pendingItemsTimerRef.current )
      }
    }
  }, [] )

  const resetChat = useCallback( () => {
    setChatVariables( {} )
    setChatLog( [] )
    setPendingChatItems( [] )
  }, [] )

  useEffect( () => {
    // useQuery is reloading on every render, so using this to cache the result
    // and avoid empty chat data after the initial query has run
    if ( loadedChatItems.length ) {
      setChatItems( loadedChatItems )
    }
  }, [loadedChatItems] )

  const value = useMemo( () => ( {
    chatItems,
    activities,
    pendingChatItems,
    chatLog,
    chatVariables,
    chatCanvasRef,
    getChatItem,
    appendPendingChatItem,
    applyPendingChatItems,
    appendLog,
    setUserResponse,
    setChatVariable,
    scrollToLatest,
    resetChat,
  } ), [
    chatItems, 
    activities,
    pendingChatItems, 
    chatLog, 
    chatVariables, 
    getChatItem, 
    applyPendingChatItems, 
    scrollToLatest,
    resetChat,
  ] )

  return (
    <ChatContext.Provider 
      value={ value }
    >
      { children }
    </ChatContext.Provider>
  )
}
