import { useEffect } from 'react'

const useWindowScroll = ( handler = null, deps = [] ) => {
  useEffect( () => {
    if ( handler && typeof window !== 'undefined' ) {
      window.addEventListener( 'scroll', handler )
      return () => window.removeEventListener( 'scroll', handler )
    }
  }, deps )
}

export default useWindowScroll
