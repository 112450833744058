import { ApolloClient, InMemoryCache } from '@apollo/client'

const getOptions = authToken => {
  const options = {
    headers: {},
  } as any

  if ( typeof window === 'undefined' ) {
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    options.uri = 'https://caddy/gql/v1/graphql'
    options.headers.Host = process.env.APP_HOSTNAME
  }
  else {
    options.uri = '/gql/v1/graphql'
  }

  if ( authToken ) {
    options.headers.Authorization = `Bearer ${ authToken }`
  }

  return options
}

const client = ( { siteHandle, authToken = null } ) => new ApolloClient( {
  defaultOptions: {
    query: {
      variables: { siteHandle },
    },
    watchQuery: {
      variables: { siteHandle },
    },
  },
  ...getOptions( authToken ),
  cache: new InMemoryCache( {
    typePolicies: {
      Query: {
        fields: {
          responses: {
            keyArgs: ['where'],
            merge( existing, incoming, { args: { offset = 0 } } ) {
              const merged = existing ? [...existing] : []

              for ( let i = 0; i < incoming.length; i += 1 ) {
                merged[ offset + i ] = incoming[ i ]
              }

              return merged
            },
          },
        },
      },
    },
  } ),
} )

export default client
