import { rgba } from 'polished'
import { css } from 'styled-components'

const textLink = css`
  color: inherit;
  text-decoration: solid underline ${ p => p.theme.colors.highlight } 1px;
  text-underline-offset: 3px;
`

export const textLinkWithHover = css`
  ${ textLink }
  transition: background-color .3s ease-out;

  &:hover {
    background-color: ${ p => rgba( p.theme.colors.highlight, 0.2 ) };
  }
`

export const soloTextLink = css`
  ${ p => p.theme.typo.neueHaasBold }
  font-size: ${ p => p.theme.typo.sizes.dropped };
  line-height: 1.4;
  color: inherit;
  text-decoration-color: ${ p => p.theme.colors.highlight };
  text-underline-offset: 3px;
`

export default textLink
