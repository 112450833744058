import styled, { css } from 'styled-components'
import Container, { ShiftAlign } from './layout/Container'
import { soloTextLink } from '../styles/textLink'
import Button from './Button'

function Hero( {
  title, 
  subheading = null,
  html = null,
  light = false, 
  bottomPad = undefined,
  collapse = false,
  elementRef = null, 
  children = null, 
} ) {
  const color = light ? 'grey9' : 'grey2'

  return (
    <StyledWrapper ref={ elementRef }>
      <Container>
        <ShiftAlign>
          <StyledHero 
            $color={ color }
            $bottomPad={ bottomPad }
            $collapse={ collapse }
          >
            <StyledHeading>
              <h1>{ title }</h1>
              { subheading && <p>{ subheading }</p> }
            </StyledHeading>
            { children && (
              <StyledBody>
                { children }
              </StyledBody>
            ) }
            { html && <StyledBody dangerouslySetInnerHTML={ { __html: html } } /> }
          </StyledHero>
        </ShiftAlign>
      </Container>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  scroll-margin-top: -2rem;
`

const StyledHeading = styled.div`
  min-height: 95px;

  > * {
    margin: 0;
  }
  
  h1 {
    ${ p => p.theme.typo.neueHaasBold }
    font-size: ${ p => p.theme.typo.sizes.hero };
    line-height: 1;
    color: inherit;
  }

  p {
    margin-top: 0.4rem;
    ${ p => p.theme.typo.neueHaas }
    font-size: ${ p => p.theme.typo.sizes.hero };
  }
`

const StyledBody = styled.div`
  p:first-of-type {
    ${ p => p.theme.typo.neueHaasBold }
    font-size: ${ p => p.theme.typo.sizes.large };
  }

  a {
    color: inherit;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    text-decoration-color: ${ p => p.theme.colors.highlight };
  }

  > a,
  p a:only-child {
    ${ soloTextLink }
  }

  > :last-child {
    margin-bottom: 0;
  }

  ${ Button } {
    display: block;
    margin-top: 1rem;
    font-size: ${ p => p.theme.typo.sizes.dropped };
  }
`

const StyledHero = styled.div<{
  $color: string,
  $bottomPad?: 'small' | 'large',
  $collapse: boolean,
}>`
  padding: 12rem 0 ${ p => p.$bottomPad === 'large' ? '4rem' : '2rem' };
  color: ${ p => p.theme.getColor( p.$color ) };

  > * {
    margin: 0 0 3rem 0;
    max-width: 45rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media ( min-width: 900px ) {
    padding-top: 2rem;
    padding-bottom: ${ p => p.$bottomPad === 'large' ? '6rem' : '2rem' };

    > * {
      margin-bottom: ${ p => p.$collapse ? 0 : '7rem' };
    }
  }

  ${ p => p.$collapse && css`
    ${ StyledHeading } {
      min-height: auto;
    }
  ` }
`

StyledHero.defaultProps = {
  $bottomPad: 'large',
}

export default Hero
