import {
  useState, createContext, useContext, useMemo,
} from 'react'

const AppContext = createContext( {
  globals: {} as { [key: string]: any },
  hasViewedIntroSplash: false,
  setHasViewedIntroSplash: null,
} )

AppContext.displayName = 'AppContext'

export const useAppContext = () => useContext( AppContext )

export function AppProvider( { globals, children } ) {
  const [hasViewedIntroSplash, setHasViewedIntroSplash] = useState( false )

  const value = useMemo( () => ( {
    globals,
    hasViewedIntroSplash,
    setHasViewedIntroSplash,
  } ), [
    globals,
    hasViewedIntroSplash,
  ] )

  return (
    <AppContext.Provider 
      value={ value }
    >
      { children }
    </AppContext.Provider>
  )
}
