import { FormattedMessage } from 'react-intl'
import Background from '../components/layout/Background'
import AppChrome from '../components/AppChrome'
import Hero from '../components/Hero'
import Link from '../components/Link'

function PageNotFound() {
  return (
    <AppChrome letsDiscussButton={ false }>
      <Background>
        <Hero 
          title={ (
            <FormattedMessage
              description="Page not found"
              defaultMessage="Page not found"
              id="OuZSS7"
            />
          ) }
        >
          <p>
            <FormattedMessage
              description="There’s no page at this address"
              defaultMessage="There’s no page at this address."
              id="t37S7L"
            />
          </p>
          <p>
            <FormattedMessage
              description="Please start at the homepage"
              defaultMessage="Please start at the <a>homepage</a>"
              values={ { a: HomepageLink } }
              id="KKxBVo"
            />
          </p>
        </Hero>
      </Background>
    </AppChrome>
  )
}

function HomepageLink( chunks ) {
  return <Link href="/">{ chunks }</Link>
}

export default PageNotFound
