import styled, { css } from 'styled-components'
import type { ColorKeys } from '../../styles/colors'

function BurgerIcon( { color = null, isOpen = false }: { color?: ColorKeys, isOpen: boolean } ) {
  const c = isOpen ? 'grey9' : ( color || 'grey8' )

  return (
    <StyledBurgerIcon 
      $color={ c }
      $isOpen={ isOpen }
    >
      <svg 
        width="40"
        height="21"
        viewBox="0 0 40 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line 
          x1="0.19043"
          y1="2.5"
          x2="40"
          y2="2.5"
          strokeWidth="3"
        />
        <line 
          x1="0.19043"
          y1="10.5"
          x2="40"
          y2="10.5"
          strokeWidth="3"
        />
        <line 
          x1="0.19043"
          y1="18.5"
          x2="40"
          y2="18.5"
          strokeWidth="3"
        />
      </svg>

    </StyledBurgerIcon>
  )
}

const StyledBurgerIcon = styled.div<{ 
  $color: ColorKeys,
  $isOpen: boolean,
}>`
  svg line {
    stroke: ${ p => p.theme.getColor( p.$color ) };
    transition: 
      stroke .3s linear,
      transform .3s ease-out,
      opacity .3s ease-out;

    &:first-child {
      transform-origin: 0% 3px;
    }
    
    &:last-child {
      transform-origin: 0% calc( 100% - 3px );
    }
  }

  ${ p => p.$isOpen && css`
    svg {
      line:nth-child( 2 ) {
        opacity: 0;
      }

      line:first-child {
        transform: rotateZ( 26deg ) translateY( -1px );
      }
      
      line:last-child {
        transform: rotateZ( -26deg ) translateY( 1px );
      }
    }
  ` }
`

export default BurgerIcon
