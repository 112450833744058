import { useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { rgba } from 'polished'
import { useChatContext } from './chat/ChatContext'
import Link from './Link'
import Container from './layout/Container'

function LetsDiscussButton( { visible = true } ) {
  const { chatLog } = useChatContext()
  const [isVisible, setIsVisible] = useState( false )

  useEffect( () => {
    requestAnimationFrame( () => requestAnimationFrame( () => setIsVisible( visible ) ) )
  }, [visible] )

  return (
    <StyledLetsDiscussButton 
      $isVisible={ isVisible }
      $pulse={ !!chatLog.length }
    >
      <Container>
        <Link href="/lets-discuss">
          <span>
            <b>Let’s</b>
            discuss
          </span>
        </Link>
      </Container>
    </StyledLetsDiscussButton>
  )
}

const pulseAnim = p => keyframes`
  0% {
    box-shadow: 
      4px 4px 20px rgba(0, 0, 0, 0.25),
      0 0 0 0 ${ rgba( p.theme.colors.highlight, 0.15 ) };
  }

  80% {
    box-shadow: 
      4px 4px 20px rgba(0, 0, 0, 0.25),
      0 0 0 15px ${ rgba( p.theme.colors.highlight, 0.25 ) };
  }
  
  100% {
    box-shadow: 
      4px 4px 20px rgba(0, 0, 0, 0.25),
      0 0 0 15px ${ rgba( p.theme.colors.highlight, 0 ) };
  }
`

const StyledLetsDiscussButton = styled.div<{ 
  $isVisible: boolean,
  $pulse?: boolean,
}>`
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  a {
    display: block;
    position: absolute;
    overflow: hidden;
    bottom: min( 3vw, 3vh );
    right: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 185px;
    height: 185px;
    border-radius: 100%;
    border: 0;
    background: radial-gradient(50% 50% at 50% 50%, #903EFF 65.41%, #A952FF 100%);
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25); 
    color: ${ p => p.theme.colors.grey9 };
    ${ p => p.theme.typo.neueHaasBold }
    font-size: 52px;
    line-height: 0.85;
    text-decoration: none;
    transition:
      opacity .3s ease-out,
      transform .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    ${ p => p.$pulse && css`
      animation: ${ pulseAnim( p ) } 2s linear infinite forwards;
    ` }

    span {
      position: relative;
      display: block;
      margin-left: -0.7rem;
      text-align: left;
      opacity: 0.6;
      transition: opacity .3s ease-out;

      b {
        display: block;
        padding-left: 1rem;
      }
    }

    &:hover {
      transform: scale( 1.05 );

      span {
        opacity: 0.8;
      }
    }
    
    @media ( max-width: 599px ) {
      right: 8px;
      bottom: 8px;

      transform: 
        translate( 25%, 25% )
        scale( 0.5 );

      &:hover {
        transform: 
          translate( 25%, 25% )
          scale( 0.55 );
      }
    }
  }

  ${ p => !p.$isVisible && css`
    a {
      opacity: 0;
      transform: scale( 0 );
      transition:
        opacity .3s ease-out,
        transform .3s cubic-bezier(0.175, 0.885, 0.32, 1.275) .15s;
    }
  ` }
`

export default LetsDiscussButton
