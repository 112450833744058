import Head from 'next/head'
import { useRouter } from 'next/router'
import { imageUrl } from './Image'

interface MetaTags {
  type?: 'article' | 'profile' | 'website',
  url: string,
  title: string,
  description?: string,
  image?: string,
  twitterCard?: 'summary' | 'summary_large_image',
}

function MetaTags( {
  type = 'website',
  url,
  title,
  description = null,
  image = null,
  twitterCard = null,
} ) {
  const { locale, defaultLocale } = useRouter()
  const localePrefix = locale === defaultLocale ? '' : `/${ locale }`
  const twitterCardType = twitterCard || image ? 'summary_large_image' : 'summary'

  const ogImage = image && imageUrl( {
    src: image,
    width: 1200,
    height: 630,
  } )
  
  const twitterImage = image && imageUrl( {
    src: image,
    width: 1200,
    height: 675,
  } )

  const metaTitle = title ? `${ title } — Houses of Darkness` : 'Houses of Darkness'
  const metaUrl = `https://${ process.env.NEXT_PUBLIC_APP_HOSTNAME }${ localePrefix }${ url || '' }`

  return (
    <Head>
      <title>{ metaTitle }</title>
      <meta property="og:title" content={ metaTitle } />
      <meta property="twitter:title" content={ metaTitle } />
      <meta property="og:type" content={ type } />
      <meta name="twitter:card" content={ twitterCardType } />
      <meta property="og:url" content={ metaUrl } />
      <meta property="twitter:url" content={ metaUrl } />
      { description && (
        <>
          <meta property="description" content={ description } /> 
          <meta property="og:description" content={ description } /> 
          <meta property="twitter:description" content={ description } /> 
        </>
      ) }
      { ogImage && <meta property="og:image" content={ ogImage } /> }
      { twitterImage && <meta property="twitter:image" content={ twitterImage } /> }
    </Head>
  )
}

export default MetaTags
