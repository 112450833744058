import styled, { css } from 'styled-components'

export const containerWidth = 87 // vw
export const containerPaddingWidth = ( 100 - containerWidth ) / 2
export const defaultMaxWidth = 85 // rem

/**
 * Only works when Container has a maxWidth
 */
export const getMaxElementWidthInRem = ( { 
  maxWidth = defaultMaxWidth,
  cols = 1,
  gutter = 0,
} ) => ( maxWidth - ( gutter * ( cols - 1 ) ) ) / cols

interface Container {
  $max?: number | null;
  $centered?: boolean,
  $fullWidth?: boolean,
}

const Container = styled.div<Container>`
  position: relative;
  display: block;
  box-sizing: border-box;
  width: ${ p => p.$fullWidth ? '100%' : 'auto' };
  margin: 0px auto;

  ${ p => !p.$fullWidth && css`
    padding-left: ${ containerPaddingWidth }%;
    padding-right: ${ containerPaddingWidth }%;
  ` }

  ${ p => p.$max && css`
    max-width: ${ p.$max }rem;

    ${ p.$fullWidth && css`
      max-width: calc( ${ p.$max }rem + ( ${ containerPaddingWidth }vw * 2 ) );
    ` }
  ` }

  ${ p => p.$centered && css`
    text-align: center;
  ` }
`

Container.defaultProps = {
  $max: defaultMaxWidth,
}

export default Container

export const ShiftAlign = styled.div<{
  $colShift?: number,
  $totalCols?: number,
  $colShiftEnd?: number,
  $breakpoint?: string,
}>`
  @media ( min-width: ${ p => p.$breakpoint } ) {
    margin-left: var( --grid-parent-gutter );
    margin-right: var( --grid-parent-gutter );
    padding-left: calc( ${ p => ( p.$colShift / p.$totalCols ) * 100 }% + var( --grid-child-gutter ) );

    ${ p => p.$colShiftEnd && css`
      padding-right: calc( ${ ( p.$colShiftEnd / p.$totalCols ) * 100 }% + var( --grid-child-gutter ) );
    ` }
  }
`

ShiftAlign.defaultProps = {
  $colShift: 2,
  $totalCols: 12,
  $breakpoint: '900px',
}
