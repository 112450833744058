import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { nl2p } from '../lib/strings'
import { useAppContext } from './AppContext'
import Container from './layout/Container'
import Stack from './layout/Stack'
import { textLinkWithHover } from '../styles/textLink'
import Link from './Link'

function Footer() {
  const { globals } = useAppContext()
  const footer = globals?.websiteFooter

  return (
    <StyledFooter>
      <Container>
        <Stack
          $top={ 3 }
          $bottom={ 3 }
        >
          <StyledFooterGrid>
            { footer?.footerCopy && (
            <div>
              { nl2p( footer.footerCopy ) }
            </div>
            ) }
            { footer?.footerMeta?.length > 0 && (
            <div>
              { footer.footerMeta.map( fm => (
                <StyledFooterMetaBlock key={ fm.id }>
                  { fm.blockTitle && <h4>{ fm.blockTitle }</h4> }
                  <div 
                        // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={ { __html: fm.blockContent } }
                  />
                </StyledFooterMetaBlock>
              ) ) }
            </div>
            ) }
            { footer?.navigationLinks?.length > 0 && (
            <div>
              <StyledFooterMetaBlock>
                <h4>
                  <FormattedMessage
                    description="Navigate"
                    defaultMessage="Navigate"
                    id="Yi2nVE"
                  />
                </h4>
                { footer.navigationLinks.map( navLink => (
                  <FooterNavLink
                    key={ navLink.id }
                    navLink={ navLink }
                  />
                ) ) }
              </StyledFooterMetaBlock>
            </div>
            ) }
          </StyledFooterGrid>
        </Stack>
      </Container>
    </StyledFooter>
  )
}

function FooterNavLink( { navLink } ) {
  if ( navLink.linkUrl ) {
    return <Link href={ navLink.linkUrl }>{ navLink.linkText }</Link>
  }

  return <span>{ navLink.linkText }</span>
}

const StyledFooter = styled.footer`
  position: relative;
  background: ${ p => p.theme.colors.grey9 };
`

const StyledFooterGrid = styled.div`
  display: grid;
  margin: var( --grid-parent-gutter );

  > * {
    margin: var( --grid-child-gutter );
  }
  
  @media ( min-width: 600px ) {
    grid-template-columns: ${ ( 6 / 12 ) * 100 }% ${ ( 3 / 12 ) * 100 }% ${ ( 3 / 12 ) * 100 }%;
  }

  > *:first-child {
    ${ p => p.theme.typo.neueHaasBold }
    font-size: ${ p => p.theme.typo.sizes.large };

    > * {
      max-width: 28rem;

      &:first-child {
        margin-top: 0;
      }
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

const StyledFooterMetaBlock = styled.div`
  ${ p => p.theme.typo.neueHaas }
  
  &:not( :first-child ) {
    margin-top: 1rem;
  }

  h4 {
    margin: 0;
    ${ p => p.theme.typo.neueHaas }
    font-size: ${ p => p.theme.typo.sizes.normal };
    color: ${ p => p.theme.colors.grey7 };
  }

  p {
    ${ p => p.theme.colors.grey2 };

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    a {
      ${ textLinkWithHover }
    }
  }

  > a {
    display: block;
    ${ textLinkWithHover }
  }
`

export default Footer
