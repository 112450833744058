import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import useModalTraits from '../hooks/useModalTraits'

const localNames = {
  en: 'English',
  de: 'Deutsch',
  nl: 'Nederlands',
  no: 'Norsk',
}

function LanguageSwitcher( { buttonColor } ) {
  const {
    locales, locale, pathname, query, events,
  } = useRouter()
  
  const [isOpen, setIsOpen] = useState( false )
  const containerRef = useRef()

  useModalTraits( {
    isOpen,
    close: () => setIsOpen( false ),
    containerRef,
    focusableElementsSelector: 'a, button',
    preventBodyScroll: false,
  } )

  useEffect( () => {
    const close = () => setIsOpen( false )
    events.on( 'routeChangeStart', close )

    return () => {
      events.off( 'routeChangeStart', close )
    }
  }, [events] )

  if ( !locales?.length ) {
    return null
  }

  return (
    <StyledLanguageSwitcher 
      $isOpen={ isOpen }
      ref={ containerRef }
    >  
      <StyledLanguageSwitcherPanel>
        <div>
          { locales.map( l => (
            <Link 
              key={ l }
              href={ {
                pathname,
                query,
              } }
              locale={ l }
            >
              <a 
                hrefLang={ l }
                className={ l === locale ? 'is-active' : undefined }
              >
                { localNames[ l ] }
              </a>
            </Link>
          ) ) }
        </div>
      </StyledLanguageSwitcherPanel>
      <StyledLanguageSwitcherToggle $color={ isOpen ? 'grey9' : buttonColor }>
        <button
          type="button"
          onClick={ () => setIsOpen( s => !s ) }
        >
          <span>{ locale.charAt( 0 ).toUpperCase() + locale.slice( 1 ) }</span>
        </button>
      </StyledLanguageSwitcherToggle>
    </StyledLanguageSwitcher>
  )
}

const StyledLanguageSwitcherPanel = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  background: ${ p => p.theme.colors.grey5 };
  opacity: 0;
  transition:
    width .3s ease-out .3s,
    opacity .2s ease-out .3s,
    height .3s ease-out .3s;

  > div {
    padding: 2.5rem 0.5rem 0.5rem;
  }

  a {
    display: block;
    color: ${ p => p.theme.colors.grey8 };
    text-decoration: none;
    opacity: 0;
    transition: 
      color .3s ease-out,
      opacity .3s ease-out;

    &:hover,
    &.is-active {
      color: ${ p => p.theme.colors.white };
    }
  }
`

const StyledLanguageSwitcher = styled.div<{ $isOpen: boolean }>`
  position: relative;
  display: flex;
  justify-content: flex-end;

  ${ p => p.$isOpen && css`
    ${ StyledLanguageSwitcherPanel } {
      width: 107px;
      height: 144px;
      pointer-events: all;
      opacity: 1;
      transition:
        width .3s ease-out,
        height .3s ease-out,
        opacity .2s ease-out;

      a {
        opacity: 1;
        transition: 
          color .3s ease-out,
          opacity .3s ease-out .3s;
      }
    }
  ` }
`

const StyledLanguageSwitcherToggle = styled.div<{
  $color: string,
}>`
  position: relative;

  button {
    margin-top: -6px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    padding: 0 1rem;
    font-size: ${ p => p.theme.typo.sizes.dropped };
    border: none;
    background-color: transparent;
    color: ${ p => p.theme.getColor( p.$color ) };
    transition: color .3s ease-out;
  }
`

export default LanguageSwitcher
