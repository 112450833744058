import { useRouter } from 'next/router'
import { ApolloProvider as Ap } from '@apollo/client'
import { useAuth } from '../lib/auth'
import client from './client'

function ApolloProvider( { children } ) {
  const me = useAuth()
  const { locale, defaultLocale } = useRouter()

  return (
    <Ap client={ client( {
      authToken: me?.user?.accessToken, 
      siteHandle: `hod${ ( locale || defaultLocale || 'en' ).replace( /./, c => c.toUpperCase() ) }`,
    } ) }
    >
      { children }
    </Ap>
  )
}

export default ApolloProvider
