export type ColorKeys = 
  'black' 
  | 'white'
  | 'purple'
  | 'highlight'
  | 'red'
  | 'grey1'
  | 'grey2'
  | 'grey3'
  | 'grey4'
  | 'grey5'
  | 'grey6'
  | 'grey7'
  | 'grey8'
  | 'grey9'

const colors = {
  black: '#000000',
  white: '#ffffff',
  purple: '#903EFF',
  red: '#f20c1f',
  grey1: '#0F0F0F',
  grey2: '#1c1d1e',
  grey3: '#2c2e30',
  grey4: '#393b3d',
  grey5: '#525455',
  grey6: '#757575',
  grey7: '#989898',
  grey8: '#c8c8ca',
  grey9: '#f0f0ec',
  highlight: '',
}

colors.highlight = colors.purple

export const getColor = ( color: ColorKeys ) => {
  if ( colors[ color ] ) {
    return colors[ color ]
  }

  return color
}

export default colors
