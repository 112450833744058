import { createPortal } from 'react-dom'
import styled, { css, keyframes } from 'styled-components'
import { useEffect, useState } from 'react'
import usePrevious from '../hooks/usePrevious'
import usePreventBodyScroll from '../hooks/usePreventBodyScroll'

function Loader( { 
  isLoading,
  background = 'grey9',
  color = 'highlight',
  fullscreen = false,
  inline = false,
  width = null,
  onComplete = null,
} ) {
  const [internalIsLoading, setInternalIsLoading] = useState( isLoading )
  const previousIsLoading = usePrevious( isLoading )

  usePreventBodyScroll( fullscreen && internalIsLoading, 'loader' )

  useEffect( () => {
    if ( previousIsLoading === true && isLoading === false ) {
      setTimeout( () => {
        setInternalIsLoading( false )

        if ( onComplete ) {
          onComplete()
        }
      }, 1000 )
    }
    else if ( isLoading === true ) {
      setInternalIsLoading( true )
    }
  }, [isLoading, onComplete, previousIsLoading] )

  const isComplete = internalIsLoading === true && isLoading === false

  if ( fullscreen && typeof window === 'object' ) {
    return (
      createPortal(
        <TheLoader 
          isComplete={ isComplete }
          isVisible={ internalIsLoading }
          background={ background }
          color={ color }
          fullscreen={ fullscreen }
          inline={ inline }
          width={ width }
        />,
        document.getElementById( 'hod-overlay-portal' ),
      )
    )
  }

  return (
    <TheLoader 
      isComplete={ isComplete }
      isVisible={ internalIsLoading }
      background={ background }
      color={ color }
      fullscreen={ fullscreen }
      inline={ inline }
      width={ width }
    />
  )
}

function TheLoader( {
  isComplete,
  isVisible,
  background,
  color,
  fullscreen,
  inline,
  width,
} ) {
  return (
    <StyledLoader 
      $isComplete={ isComplete }
      $isVisible={ isVisible }
      $background={ background }
      $color={ color }
      $fullscreen={ fullscreen }
      $inline={ inline }
      $width={ width }
    >
      <svg 
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle 
          className="ring-track" 
          fill="transparent"
          strokeWidth="6px"
          stroke="#9c9c9c30"
          cx="50"
          cy="50" 
          r="44"
        />
        <circle 
          className="loader-ring" 
          fill="transparent"
          strokeWidth="6px"
          stroke="#ec5c0e"
          strokeDashoffset="276.460"
          strokeDasharray="276.460 276.460"
          cx="50"
          cy="50"
          r="44"
        />
        <circle 
          className="loader-ring-overlay" 
          fill="transparent"
          strokeWidth="6px"
          stroke="#ec5c0e"
          strokeDashoffset="276.460"
          strokeDasharray="276.460 276.460"
          cx="50"
          cy="50"
          r="44"
        />
      </svg>
    </StyledLoader>
  )
}

const startingFill = keyframes`
  to {
    stroke-dashoffset: 270;
  }
`

const varyLoaderWidth = keyframes`
  0% {
    stroke-dashoffset: 270;
  }
  50% {
    stroke-dashoffset: 170;
  }
  100% {
    stroke-dashoffset: 275;
  }
`

const completeFill = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`

const StyledLoader = styled.div<{ 
  $isComplete: boolean,
  $isVisible: boolean,
  $background: string,
  $color: string,
  $fullscreen: boolean,
  $inline: boolean,
  $width: string,
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: ${ p => p.$isVisible ? 'auto' : 'none' };
  opacity: ${ p => p.$isVisible ? 1 : 0 };
  transition: opacity .3s ease-out;

  ${ p => p.$fullscreen && css`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background: ${ p.theme.getColor( p.$background ) };
  ` }

  ${ p => !p.$inline && !p.$fullscreen && css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${ p.theme.getColor( p.$background ) };
  ` }

  ${ p => p.$inline && css`
    position: relative;
    width: ${ p.$isVisible ? p.$width : '0' };
    transition: 
      width .3s ease-out,
      opacity .3s ease-out;
  ` }

  svg {
    width: 80%;
    height: 80%;
    max-width: 70px;
    transition: opacity .2s linear .7s;

    .loader-ring {
      stroke: ${ p => p.theme.getColor( p.$color ) };
      transform-origin: 50px 50px;
      animation: 
        ${ startingFill } 0.5s forwards,
        ${ varyLoaderWidth } 3s 0.5s linear infinite alternate,
        ${ spin } 1.6s 0.2s linear infinite;
    }

    .loader-ring-overlay {
      stroke: ${ p => p.theme.getColor( p.$color ) };
      visibility: hidden;
      transform-origin: 50px 50px;
      animation: ${ spin } 1.6s 0.2s linear infinite;
    }
  }

  ${ p => p.$isComplete && css`
    svg {
      opacity: 0;
      cursor: initial;
      pointer-events: none;
      
      .loader-ring-overlay {
        visibility: visible;
        animation: 
          ${ completeFill } 0.5s linear forwards, 
          ${ spin } 1.6s 0.2s linear infinite;
      }
      
      .loader-ring {
        animation: 
          ${ startingFill } 0.5s forwards,
          ${ varyLoaderWidth } 3s 0.5s linear infinite alternate,
          ${ spin } 1.6s 0.2s linear infinite, fade 0.1 0.5s linear forwards;
      }
    }
  ` }
`

export default Loader
