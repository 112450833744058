import {
  createContext, 
  useContext, 
  useState, 
  useEffect, 
  useRef, 
  useMemo,
  useCallback,
} from 'react'
import { useRouter } from 'next/router'
import can from './can'

const APP_HOSTNAME = process.env.NEXT_PUBLIC_APP_HOSTNAME

type AuthenticateResponse = {
  isAuthenticated: boolean,
  user: any,
  error: any,
}

const authenticate = () => fetch( `https://${ APP_HOSTNAME }/api/auth/me`, {
  credentials: 'include',
} )
  .then( response => new Promise<AuthenticateResponse>( ( resolve, reject ) => {
    response.json()
      .then( responseData => resolve( {
        ...responseData,
        shouldRefresh: response.status === 401,
      } ) )
      .catch( e => reject( e ) )
  } ) )

export const logout = () => fetch( `https://${ APP_HOSTNAME }/api/auth/logout`, {
  method: 'POST',
  credentials: 'include',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
} )
  .then( result => result.json() )

const AuthContext = createContext( {
  isAuthenticated: null,
  loading: null,
  user: null,
  can: can( [], {} ),
  authenticate: null,
  logout: () => null,
} )

AuthContext.displayName = 'AuthContext'

export const useAuth = ( {
  isPrivate = null,
} = {} ) => {
  const router = useRouter()
  const auth = useContext( AuthContext )

  useEffect( () => {
    if (
      isPrivate
      && auth.loading === false
      && auth.isAuthenticated === false
    ) {
      router.push( isPrivate )
    }
  }, [auth.isAuthenticated, auth.loading, isPrivate, router] )

  return auth
}

export const useRedirectOnAuth = redirect => {
  const [isRedirecting, setIsRedirecting] = useState( false )
  const { isAuthenticated, user } = useAuth()

  useEffect( () => {
    let isMounted = true

    if ( isMounted && isAuthenticated ) {
      setIsRedirecting( true )
      redirect( user )
    }

    return () => {
      isMounted = false
    }
  }, [isAuthenticated, user, redirect] )

  return isRedirecting
}

export function AuthProvider( { serverSideAuthResponse = null, children } ) {
  const [loading, setLoading] = useState( null )

  const [authState, setAuthState] = useState( {
    user: serverSideAuthResponse?.user,
    isAuthenticated: serverSideAuthResponse?.isAuthenticated || null,
    error: serverSideAuthResponse?.error,
  } )

  const isMounted = useRef( null )
  const timer = useRef( null )

  const setUnathenticated = error => setAuthState( {
    user: null,
    isAuthenticated: false,
    error,
  } )

  const authenticateAndSet = useCallback(
    () => authenticate()
      .then( authResponse => {
        setAuthState( authResponse )
        return authResponse
      } )
      .catch( e => {
        setUnathenticated( e.message )
      } ),
    [],
  )

  const authValues = useMemo( () => ( {
    ...authState,
    loading,
    authenticate: authenticateAndSet,
    logout: () => logout().then( authenticateAndSet ),
    can: can( authState?.user?.allowedRoles, authState?.user ),
  } ), [authState, authenticateAndSet, loading] )

  useEffect( () => {
    if (
      typeof authState.isAuthenticated === 'undefined'
      || authState.isAuthenticated === null
    ) {
      // Authentication wasn’t attempted yet
      setLoading( true )
      authenticateAndSet()
        .finally( () => {
          if ( isMounted.current ) {
            setLoading( false )
          }
        } )
    }
  }, [authState.isAuthenticated, authenticateAndSet] )

  useEffect( () => {
    if ( authState.user?.exp ) {
      const expiresIn = authState.user.exp - Math.floor( Date.now() / 1000 )

      timer.current = setTimeout(
        authenticate,
        Math.round( expiresIn * 0.75 ) * 1000,
      )

      return () => {
        if ( timer.current ) {
          clearTimeout( timer.current )
        }
      }
    }
  }, [authState.user?.exp] )

  useEffect( () => {
    const checkAuth = () => {
      if ( authState.user?.exp < Math.floor( Date.now() / 1000 ) - 30 ) {
        authenticateAndSet()
      }
    }

    window.addEventListener( 'focus', checkAuth )

    return () => {
      window.removeEventListener( 'focus', checkAuth )
    }
  }, [authState.user?.exp, authenticateAndSet] )

  useEffect( () => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [] )

  return (
    <AuthContext.Provider value={ authValues }>
      { children }
    </AuthContext.Provider>
  )
}
