import type { ColorKeys } from '../../styles/colors'
import { getColor } from '../../styles/colors'

function XIcon( { color = null, title = null }: { color?: ColorKeys, title?: string } ) {
  const c = getColor( color ) || 'grey8'

  return (
    <svg 
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      { title && <title>{ title }</title> }
      <line 
        x1="1.35355"
        y1="0.646447"
        x2="25.3536"
        y2="24.6464"
        stroke={ c }
      />
      <line 
        y1="-0.5"
        x2="33.9411"
        y2="-0.5"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 25 1)"
        stroke={ c }
      />
    </svg>

  )
}

export default XIcon
