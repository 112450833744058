import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { IntlProvider as IP } from 'react-intl'
import en from '../compiled-lang/en.json'
import de from '../compiled-lang/de.json'
import nl from '../compiled-lang/nl.json'
import no from '../compiled-lang/no.json'

function IntlProvider( { children } ) {
  const { locale, defaultLocale } = useRouter()
  const l = locale || defaultLocale || 'en'

  const messages = useMemo( () => {
    switch ( l ) {
      case 'en':
        return en
      case 'de':
        return de
      case 'nl':
        return nl
      case 'no':
        return no
      default:
        return en
    }
  }, [l] )

  return (
    <IP 
      locale={ l } 
      defaultLocale={ defaultLocale || 'en' }
      messages={ messages }
    >
      { children }
    </IP>
  )
}

export default IntlProvider
