import { useMemo } from 'react'
import styled from 'styled-components'
import Link from './Link'
import Container from './layout/Container'
import HODLogo from './HODLogo'
import Nav from './Nav'
import LanguageSwitcher from './LanguageSwitcher'

function Header( { 
  isSplashVisible, 
  darkPage = false,
  logoColor: lc = null,
  noLogo = false,
} ) {
  const logoColor = useMemo( () => {
    if ( isSplashVisible ) {
      return 'grey6'
    }

    return lc || ( darkPage ? 'grey9' : 'grey2' )
  }, [isSplashVisible, darkPage, lc] )

  return (
    <StyledHeader>
      <Container>
        { !noLogo && (
          <StyledHeaderLogoWrapper>
            <Link href="/">
              <HODLogo color={ logoColor } />
            </Link>
          </StyledHeaderLogoWrapper>
        ) }
        <StyledHeaderNav>
          <LanguageSwitcher buttonColor={ logoColor } />
          <Nav buttonColor={ logoColor } />
        </StyledHeaderNav>
      </Container>
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  > ${ Container } {
    display: flex;
  }
`

const StyledHeaderLogoWrapper = styled.div`
  margin-top: 2rem;

  a {
    pointer-events: auto;
  }
`

const StyledHeaderNav = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  margin-left: auto;
  pointer-events: auto;

  > *:not( :last-child ) {
    margin-right: 0.6rem;
  }
`

export default Header
