import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { useModalContext } from '../lib/modal'
import XIcon from './icons/XIcon'
import Loader from './Loader'

function Modal( { children } ) {
  const {
    isOpen, 
    isLoading, 
    params,
    modalContainerRef, 
    closeModal, 
  } = useModalContext()

  return (
    <StyledModalContainer>
      <StyledModalOverlay
        $isOpen={ isOpen }
      />
      <StyledModalScroller
        $isOpen={ isOpen }
      >
        <StyledModal
          ref={ modalContainerRef }
          $isOpen={ isOpen }
          $contentWidth={ params.contentWidth }
          $padding={ params.padding }
        >
          <section>
            { params.closeButton !== false && (
              <StyledModalCloseButton onClick={ closeModal }>
                <XIcon color="grey2" title="Close" />
              </StyledModalCloseButton>
            )}
            { children }
          </section>
          <Loader isLoading={ isLoading } />
        </StyledModal>
      </StyledModalScroller>
    </StyledModalContainer>
  )
}

const StyledModalContainer = styled.div`
  z-index: 3;
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  pointer-events: none;
`

const StyledModalOverlay = styled.div<{ $isOpen: boolean }>`
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${ p => rgba( p.theme.colors.grey2, 0.8 ) };
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s ease-out .3s;

  ${ p => p.$isOpen && css`
    pointer-events: auto;
    opacity: 1;
    transition: opacity .3s ease-out;
  ` }
`

const StyledModalScroller = styled.div<{ $isOpen: boolean }>`
  z-index: 1;
  position: fixed;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  pointer-events: ${ p => p.$isOpen ? 'auto' : 'none' };
`

const StyledModal = styled.div<{ 
  $isOpen: boolean,
  $contentWidth: boolean,
  $padding: boolean,
}>`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: ${ p => p.$contentWidth ? 'auto' : '100%' };
  max-width: ${ p => p.$contentWidth ? 'none' : '45rem' };
  min-height: 70vh;
  margin: auto;
  pointer-events: none;
  opacity: 0;
  transform: translateY( 5vh );
  background: ${ p => p.theme.colors.grey9 };
  transition:
    opacity .3s ease-out .2s,
    transform .3s ease-out .2s;

  ${ p => p.$isOpen && css`
    pointer-events: auto;
    opacity: 1;
    transform: translateY( 0 );
  ` }

  > section {
    flex: 1 0;
    padding: ${ p => p.$padding !== false ? 'min( 7%, 3rem )' : 0 };
    text-align: center;
  }

  h1 {
    ${ p => p.theme.typo.sigmarOne }
    font-size: ${ p => p.theme.typo.sizes.veryLarge };
    color: ${ p => p.theme.colors.grey2 };

    span {
      color: ${ p => p.theme.colors.grey2 };
    }
  }
`

const StyledModalCloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 48px;
  height: 48px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  color: inherit;
  background: transparent;
  border: 0;
  border-radius: 48px;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background .3s ease-out;

  &:hover {
    background: ${ p => p.theme.colors.grey8 };
  }

  &:focus {
    outline: none;
  }
  
  &:focus-visible {
    background: ${ p => p.theme.colors.grey8 };
  }

  &:focus:not( :focus-visible ) {
    box-shadow: none;
  }

  span {
    margin-top: -4px;
  }

  @media all and ( max-width: 600px ) {
    right: 0px;
  }
`

export default Modal
