import { css } from 'styled-components'
import colors from './colors'

export const typo = {
  neueHaas: css`
    font-family: 'Haas Grot Disp Web';
    font-weight:  500;
    font-style:   normal;
    font-stretch: normal;
  `,
  neueHaasBold: css`
    font-family: 'Haas Grot Disp Web';
    font-weight:  600;
    font-style:   normal;
    font-stretch: normal;
  `,
  sizes: {
    small: '0.777rem', // 14px
    dropped: '0.888rem', // 16px
    normal: '1rem', // 18px
    medium: '1.222rem', // 22px
    large: '1.555rem', // 28px
    smallHero: '2rem', // 28px
    hero: '2.666rem', // 48px
    bigCTA: '3.111rem', // 56px
  },
}

const fonts = css`
  html,
  body,
  input,
  textarea,
  select,
  button {
    ${ typo.neueHaas }
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${ colors.grey2 };
    font-size: 18px;
    line-height: 1.25;
  }
`

export default fonts
