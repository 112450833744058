import {
  createContext, useMemo, useContext,
} from 'react'
import Script from 'next/script'

export function GAScript( { gaTrackingId, awaitConsent = true } ) {
  if ( !gaTrackingId ) {
    return null
  }

  const deferForConsent = awaitConsent
    ? `
    gtag('consent', 'default', {
      'ad_storage': 'denied',
      'analytics_storage': 'denied',
    });
    `
    : ''

  return (
    <>
      <Script
        id="consent-defaults"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={ {
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            ${ deferForConsent }
          `,
        } }
      />
      <Script 
        id="tag-manager"
        strategy="afterInteractive" 
        dangerouslySetInnerHTML={ { 
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${ gaTrackingId }');
          `,
        } }
      />
      <noscript>
        <iframe
          title="tag-manager"
          src={ `https://www.googletagmanager.com/ns.html?id=${ gaTrackingId }` }
          height="0"
          width="0"
          style={ { display: 'none', visibility: 'hidden' } }
        />
      </noscript>
    </>
  )
}

const GAContext = createContext( { gaTrackingId: null } )
GAContext.displayName = 'GAContext'

export function GAProvider( { gaTrackingId, children } ) {
  const value = useMemo( () => ( { gaTrackingId } ), [gaTrackingId] )

  return (
    <GAContext.Provider value={ value }>
      { children }
      <GAScript
        gaTrackingId={ gaTrackingId }
        awaitConsent
      />
    </GAContext.Provider>
  )
}

export const useGA = () => {
  const { gaTrackingId } = useContext( GAContext )

  return {
    gaTrackingId,
    pageView,
    event,
    grantAnalytics,
    denyAnalytics,
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageView = () => {
  if ( typeof window !== 'undefined' && window.gtag ) {
    window.gtag( 'event', 'page_view' )
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ( {
  action, category, label, value,
} ) => {
  if ( typeof window !== 'undefined' && window.gtag ) {
    window.gtag( 'event', action, {
      event_category: category,
      event_label: label,
      value,
    } )
  }
}

export const grantAnalytics = () => {
  if ( typeof window !== 'undefined' && window.gtag ) {
    window.gtag( 'consent', 'update', {
      analytics_storage: 'granted',
    } )
  }
}

export const denyAnalytics = () => {
  if ( typeof window !== 'undefined' && window.gtag ) {
    window.gtag( 'consent', 'update', {
      analytics_storage: 'denied',
    } )
  }
}
