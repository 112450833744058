import Head from 'next/head'
import PageNotFound from './PageNotFound'
import ContextProviders from '../components/ContextProviders'
import { GAProvider } from '../lib/ga'
import '../styles/global.css'

const gaTrackingId = 'GTM-MSVX5G5'

function App( { Component, pageProps } ) {
  return (
    <GAProvider gaTrackingId={ gaTrackingId }>
      <ContextProviders globals={ pageProps?.globals }>
        <Head>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#903eff" />
          <meta name="msapplication-TileColor" content="#f0f0ec" />
          <meta name="theme-color" content="#f0f0ec" />
        </Head>
        { /* eslint-disable-next-line react/jsx-props-no-spreading */ }
        { pageProps.statusCode !== 404 && <Component { ...pageProps } /> }
        { /* eslint-disable-next-line react/jsx-props-no-spreading */ }
        { pageProps.statusCode === 404 && <PageNotFound { ...pageProps } /> }
      </ContextProviders>
    </GAProvider>
  )
}

export default App
