import type { Ref } from 'react'
import { 
  createContext,
  useContext,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react'
import useModalTraits from '../hooks/useModalTraits'

const ModalContext = createContext( {
  isOpen: false,
  isLoading: false,
  params: {} as { [key: string]: any },
  setIsLoading: null,
  modalContainerRef: null as Ref<HTMLDivElement>,
  openModal: null,
  closeModal: null,
} )

ModalContext.displayName = 'ModalContext'

export const useModalContext = () => useContext( ModalContext )

export function ModalProvider( { Modal, children } ) {
  const modalContainerRef = useRef( null )
  const [isOpen, setIsOpen] = useState( false )
  const [isLoading, setIsLoading] = useState( false )
  const [modalContent, setModalContent] = useState( null )
  const [onClose, setOnClose] = useState( null )
  const [params, setParams] = useState( {} )
  const [key, setKey] = useState( 1 )

  const openModal = ( {
    content,
    isLoading: isLoadingOnOpen = false,
    params: newParams = {},
    remount = true,
    onClose: newOnClose = null,
  } ) => {
    setModalContent( content )
    setIsOpen( true )
    setIsLoading( isLoadingOnOpen )
    setParams( newParams )
    setOnClose( () => newOnClose )

    if ( remount ) {
      setKey( k => k + 1 )
    }

    if ( modalContainerRef.current ) {
      modalContainerRef.current.scrollTop = 0
    }
  }

  const closeModal = useCallback( () => {
    setIsOpen( false )

    if ( onClose ) {
      onClose()
      setOnClose( null )
    }
  }, [onClose] )

  useModalTraits( {
    isOpen,
    close: () => setIsOpen( false ),
    containerRef: modalContainerRef,
    focusOnOpen: 'button',
  } )

  const contextValue = useMemo( () => ( {
    isOpen,
    isLoading,
    params,
    modalContainerRef,
    setIsLoading,
    openModal,
    closeModal,
  } ), [closeModal, isLoading, isOpen, params] )

  return (
    <ModalContext.Provider
      value={ contextValue }
    >
      { children }
      <Modal key={ key }>
        { modalContent }
      </Modal>
    </ModalContext.Provider>
  )
}
