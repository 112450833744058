import { useEffect, useMemo, useState } from 'react'

export const useChatbotContentQuery = languageCode => {
  const siteHandle = useMemo( 
    () => `hod${ languageCode.charAt( 0 ).toUpperCase() + languageCode.slice( 1 ).toLowerCase() }`,
    [languageCode],
  )

  const [data, setData] = useState( null )

  useEffect( () => {
    fetch( `/api/chat?siteHandle=${ siteHandle }`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    } )
      .then( r => r.json() )
      .then( r => setData( r ) )
  }, [siteHandle] )

  return data || { data: null }
}
